<template>
  <div style="background:#eee">
    <div class="downLoad_top">
      <div class="downLoad_top_center">
        <div style="text-align:right">
          <img src="../assets/external/hotel_1.1.2.png" style="width:500px;" />
        </div>
        <div>
          <div style="font-size: 50px;font-weight:700">智慧桌面iTV2022全新版本</div>
          <div style="margin-top: 30px;font-size: 22px">极速秒开观看,全功能集成</div>
          <p
            style="font-size:14px;color:#acd5ff;line-height:30px;margin-top:60px"
          >{{list[0].updateTime}}更新&nbsp;版本：{{list[0].iptvVersion}}</p>
          <p style="font-size:14px;color:#acd5ff;line-height:30px">支持windows7及以上的windows系统版本</p>
          <el-button
            type="success"
            size="default"
            style="width:242px;height:69px;font-size:27px;margin-top:20px"
          >正式版下载</el-button>
        </div>
      </div>
    </div>
    <div style="width:960px;margin:auto;background:#ffffff;padding:20px;margin-top:20px">
      <div class="downLoad_title">
        <i class="iconfont icon-LocalSource" style="font-size:25px"></i>
        <span style="margin-left:10px;letter-spacing:1px;">智慧桌面iTV</span>
        <div class="triangle"></div>
        <div class="triangle_text">for&nbsp;Windows</div>
      </div>
      <div class="lists">
        <div class="list" v-for="item in list" :key="item.id">
          <img src="../assets/external/point.png" />
          <div class="title newest">
            <span class="version">版本：{{item.iptvVersion}}</span>
          </div>
          <div class="date">{{item.updateTime}}</div>
          <div class="content">{{item.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getApkVersionApi } from "../api/data";
import DateFormart from "../utils/date";
export default {
  data() {
    return {
      list: []
    };
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getApkVersionApi().then(res => {
        for (let index = 0; index < res.data.length; index++) {
          res.data[index].updateTime = DateFormart.dataFormatYear(
            res.data[index].updateTime
          );
        }
        this.list = res.data;
      });
    }
  }
};
</script>
<style lang='scss'>
.downLoad_top {
  height: 600px;
  background: url("../assets/external/downLoadBG.png")
    repeat 100% 100%;
  color: #ffffff;
  .downLoad_top_center {
    width: 1200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin: auto;
  }
}

.downLoad_title {
  display: flex;
  align-items: center;
  font-size: 20px;
  .triangle {
    width: 0;
    height: 0;
    border-right: 5px solid #025cdf;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    margin-left: 10px;
  }
  .triangle_text {
    color: #ffffff;
    background: #025cdf;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.lists {
  width: 770px;
  padding-top: 37px;
  margin-top: 26px;
  margin-left: 147px;
  border-left: 1px solid #ddd;
  .list {
    border-top: 1px solid #eee;
    position: relative;
    padding-left: 30px;
    padding-bottom: 75px;
    img {
      position: absolute;
      left: -5px;
      top: -5px;
      z-index: 1;
      margin: 0;
      padding: 0;
      display: block;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      text-decoration: none;
    }
    .title {
      position: absolute;
      left: 0;
      top: -13px;
      display: block;
      width: 230px;
      height: 25px;
      line-height: 25px;
      background: #fff;
      font-size: 14px;
      color: #666;
      text-indent: 30px;
    }
    .date {
      position: absolute;
      left: -100px;
      top: -13px;
      height: 25px;
      line-height: 25px;
      font-size: 14px;
      color: #666;
    }
    .content {
      margin-top: 20px;
      font-size: 12px;
      color: #999;
      line-height: 25px;
      white-space: pre-line;
    }
  }
}
</style>